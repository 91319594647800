import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import getEnvironment from 'bootstrap/get-environment';
import negotiateOverrideBaseUrl from 'bootstrap/negotiate-overrride-base-url';
import { map, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { AuthError } from '@azure/msal-browser';
import { LoginConfigurationModel } from '@newApi/models/login-configuration/login-configuration.model';

const subscription = getEnvironment()
  .pipe(
    map(env => {
      const modifiedEnv = negotiateOverrideBaseUrl(env);
      // set the global environment config
      window.envConf = modifiedEnv;
    }),
    tap(async () => {
      const { environment } = await import('./environments/environment');
      const { default: getLoginConfigurations } = await import('./bootstrap/get-login-configurations');
      const { default: getLoginInfo } = await import('bootstrap/get-login-info');
      const { setLoginConfigurations } = await import('bootstrap/log-in-configuration');

      // Set up sentry.io for error tracking when running on anything else than localhost, match or dev
      const shouldEnableSentry = !environment.name.toLowerCase().startsWith('localhost')
        && window.location.hostname !== 'localhost'
        && environment.name !== 'portal-match'
        && environment.name !== 'portal-dev';
      if (shouldEnableSentry) {
        Sentry.init({
          dsn: 'https://4f739fc85fcc400650e687105f0f42dd@o4507899855503360.ingest.de.sentry.io/4507905338310736',
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
              blockAllMedia: true,
              maskAllText: false,
              maskAllInputs: true
            }),
          ],
          environment: environment.name,
          // Tracing
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          // tracePropagationTargets: ['localhost', 'https://dev.nodes-tech.com/api', 'https://portal.nodesmarket.com/api'],

          // Session Replay
          // This sets the sample rate at 10%.
          // You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysSessionSampleRate: 0.1,
          // If you're not already sampling the entire session,
          // change the sample rate to 100% when sampling sessions where errors occur.
          replaysOnErrorSampleRate: 1.0,
          beforeSend: (event, hint) => {
            const isMsalError = hint.originalException instanceof AuthError;
            if (isMsalError) {
              event.fingerprint = ['msal-auth-error'];
            }
            return event;
          }
        });
      } else {
        console.info('Sentry is disabled for environment', environment.name);
      }

      if (environment.production) {
        enableProdMode();
      }
      return getLoginConfigurations()
        .then(async (configs: LoginConfigurationModel[]) => {
          setLoginConfigurations(configs);
          const { AppModule } = await import('./app/app.module');
          platformBrowserDynamic([
            {
              provide: LoginConfigurationModel,
              useFactory: () => getLoginInfo(configs)
            },
            {
              provide: HttpClient,
              deps: [HttpHandler]
            },
            {
              provide: HttpHandler,
              useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest })
            }
          ])
            .bootstrapModule(AppModule)
            .catch(err => console.error(err));
        });
    })
  )
  .subscribe(() => subscription.unsubscribe());
